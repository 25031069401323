import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Projects from "./pages/Projects/Projects";
import React from "react";

export default function AppRouter() {
    return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/blog" element={<Blog />}/>
            <Route path="/blog/post/:id" element={<BlogPost />}/>
            <Route path="/projects" element={<Projects />}/>
        </Routes>
    );
}