import footer from "../../assets/imgs/layoutImgs/footer.png";
import cx from "classnames";
import footerNavHeader from "../../assets/imgs/layoutImgs/footerNavHeader.png";
import React, {useRef, useState} from "react";
import spaceship from "../../assets/imgs/spaceship.svg";
import PreloadImage from "../PreloadImage";

export default function Footer() {
    const footerRef = useRef<HTMLDivElement>(null);

    const [closed, setClosed] = useState<boolean>(true);

    return (
        <div className="footer" ref={footerRef}>
            <footer className="footerBase">
                <button className={cx('footerNavTrigger', {'closed': closed})} onClick={() => {
                    setClosed(!closed)
                }}>&nbsp;</button>
                <PreloadImage siteInit className="footerBackground" src={footer} alt="footer bg"/>
            </footer>
            <div className={cx('footerNav', {'closed': closed})}>
                <PreloadImage siteInit src={footerNavHeader} className="footerNavHeader" alt="sub nav header"/>
                <div className="footerNavMain">
                    <img id="spaceship" width="200" src={spaceship} alt="spaceship" />
                </div>
            </div>
        </div>
    )
}