import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter} from "react-router-dom";
import AppRoutes from './AppRoutes';
import {Helmet} from "react-helmet";
import loader from './assets/imgs/layoutImgs/loader.gif';

function App() {
    useEffect(() => {
        localStorage.removeItem('key');
        localStorage.removeItem('finished');
    }, []);

    return (
        <BrowserRouter>
            <Helmet>
                <link rel="preload" as="image" href={loader}/>
            </Helmet>
            <img alt="loader" src={loader} style={{display: 'none'}} />
            <AppRoutes/>
        </BrowserRouter>
    );
}

export default App;
