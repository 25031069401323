import React from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import kids from "../../assets/imgs/kids.jpeg";
import peterAndKids from "../../assets/imgs/peterAndKids.jpg";
import me from "../../assets/imgs/me.jpeg";
import Box, {BoxColors, BoxDirection} from "../../layout/Box";
import PreloadImage from "../../layout/PreloadImage";

export default function Home() {
    return (
        <DefaultLayout>
            <Box direction={BoxDirection.LEFT} color={BoxColors.GREEN}>
                <p className="textLarge">A portfolio site of Peter DeChamp Richardson.</p>
            </Box>
            <Box color={BoxColors.WHITE}>
                <PreloadImage className="border floatLeft margin" width={200} height={200} src={peterAndKids} alt="test"/>
                <p>Just a fun little spot for me to post about my work. I haven't had much time in the past to have a
                    website since I've been so busy working for other companies and their sites.</p>
                <p>So this will be a
                    combination of both my professional and personal projects.</p>
                <p>I started learning html when I was 11, in 1994.</p>
                <PreloadImage className="border floatRight margin" width={180} height={180} src={me} alt="test"/>
                <p>I've been doing it ever since and I love it, although I feel like I've gotten away from the passion part and just been busy with the work part.</p>
                <p>This is my attempt to break out of the rut of not having a personal place for my own creations.</p>
                <p>A little about myself. I'm a father of two amazing children. Both adopted from foster care.</p>
                <p>My son is a cancer survivor.</p>
                <PreloadImage className="border floatLeft margin" width={200} height={240} src={kids} alt="test"/>
                <p>We were blessed to be able to get to be their parents. My kids are probably the coolest kids around but we know all parents say that. :P</p>
                <p>So I'm passionate about my family and my skill set. What is my skill set?</p>
                <p>Well that is to come but for now you can check me out on linked in! That has my information.</p>
                <p><a target="_blank" href="https://www.linkedin.com/in/peter-richardson-4a786763/">Peter Richardson on LinkedIn</a></p>
                <div className="clearFloat"/>
            </Box>
        </DefaultLayout>
    );
}