import React, {ReactNode} from "react";
import cx from "classnames";

export enum BoxColors {
    WHITE = 'white',
    GREEN = 'green'
}

export enum BoxDirection {
    FULL = 'full',
    LEFT = 'left',
    RIGHT = 'right'
}

export interface BoxProps {
    children: ReactNode;
    direction?: BoxDirection;
    color?: BoxColors;
    [arg: string]: any;
}

export default function Box({children, direction = BoxDirection.FULL, color, ...rest}: BoxProps) {
    return (
        <div className={cx('box', direction, color)} {...rest}>
            {children}
        </div>
    )
}