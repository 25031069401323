import React, {ReactElement} from "react";

export interface ImageWithOverlayTextProps {
    src: string;
    onClick?: () => void;
    children: string | ReactElement | ReactElement[];
}

export default function ImageWithOverlayText({src, onClick, children}: ImageWithOverlayTextProps) {
    return (
        <div onClick={onClick} className="imageWithOverlayText">
            <img src={src}/>
            <span>{children}</span>
        </div>
    );
}