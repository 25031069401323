import React from "react";
import subNav from "../../assets/imgs/layoutImgs/subNav.png";
import logo from "../../assets/imgs/layoutImgs/logo.png";
import header from "../../assets/imgs/layoutImgs/header.png";
import {Link, useNavigate} from "react-router-dom";
import PreloadImage from "../PreloadImage";

export default function Header() {
    const navigate = useNavigate();

    return (
        <div className="header">
            <header className="headerOverlay">
                <PreloadImage siteInit width={200} position="absolute" onClick={() => navigate('/')} src={logo} className="logo" alt="logo"/>
                <PreloadImage siteInit width={866} height={90} src={header} alt="header bg"/>
                <div className="subNavLinks">
                    <Link to="/">Home</Link>
                    <Link to="/blog">Blog</Link>
                    <Link to="/projects">Projects</Link>
                </div>
            </header>
            <div className="subNav">
                <PreloadImage siteInit src={subNav} alt="subnav"/>
            </div>
        </div>
    )
}