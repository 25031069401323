import React, {ReactNode, useEffect, useLayoutEffect, useState} from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import Post from "../../layout/Post";
import {Link, useParams} from "react-router-dom";
import hljs from 'highlight.js';
import "highlight.js/scss/atom-one-dark.scss";
import {Helmet} from "react-helmet";

export default function BlogPost() {
    const {id} = useParams();

    const [content, setContent] = useState<any>({});

    useEffect(() => {
        (async () => {
            const post = await import((`../../assets/data/posts/${Number(id)}.json`));

            setContent(post);
        })();
    }, [id]);


    useLayoutEffect(() => {
        const elements = document.querySelectorAll<HTMLDivElement>('div.js-code-highlight .highlight') || [];

        elements.forEach((element) => hljs.highlightElement(element));
    });

    return (
        <DefaultLayout>
            <Helmet>
                <title>{`Blog Post - ${content?.title?.slice(0, 40) || ''}`}</title>
            </Helmet>
            <div><Link to="/blog">Back to posts</Link></div>
            {content && <Post title={content.title} date={content.published_timestamp}>
                <div dangerouslySetInnerHTML={{__html: content.body_html}}/>
            </Post>}
        </DefaultLayout>
    );
}