import React, {ReactNode, useEffect, useState} from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import Post from "../../layout/Post";
import posts from '../../assets/data/posts/posts.json';
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Blog() {
    const navigation = useNavigate();

    const [content, setContent] = useState<ReactNode[]>([]);

    useEffect(() => {
        (async () => {

            let finalContent = posts.map<ReactNode>((post, index) => {
                return [
                    <Post className="post" onClick={() => navigation(`/blog/post/${post.id}`)} key={index} title={post.title} date={post.published_timestamp}>
                        <a className="button border" href={`${post.canonical_url}`}>Read on Dev.to</a>
                        {' '}
                        <Link className="button" to={`/blog/post/${post.id}`}>Read here</Link>
                        <div dangerouslySetInnerHTML={{__html: post.description}}/>
                    </Post>
                ];
            });

            setContent(finalContent);
        })();
    }, []);

    return (
        <DefaultLayout>
            <Helmet>
                <title>Blog</title>
            </Helmet>
            {content}
            <Post date="08/09/2022" title="My first post">
                <>
                    <p>Yup, I did the whole "first post" lol. What I find is that building site templates and figuring out how to
                        make them work functionally, brings me joy.</p>
                    <p>It's the building out of content to fill in the site, is when my eye's glaze over. Puke! lol.</p>
                    <p>I don't know why content drives me nuts. Either way, I have to come up with a way to fight it. So that is why this post exist.</p>
                    <p>The thing is once I can get myself to start, then I'll keep going for a long time. So strange how the brain works.</p>
                    <hr/>
                    <p>Follow up! I was able to get so much done with this 1 post, I ended up working on my site for 2 days back to back.</p>
                    <p>Sometimes, it's just finding what works for you to move past blockers.</p>
                </>
            </Post>
        </DefaultLayout>
    );
}