import React, {ReactElement, useEffect, useState} from "react";
import cx from "classnames";

export interface FlipBookProps {
    children: ReactElement | ReactElement[];
    nav?: FlipBookNav
}

export enum FlipBookNav {
    BOTTOM = 'bottom',
    SIDE_RIGHT = 'sideRight',
}

export default function FlipBook({children, nav = FlipBookNav.SIDE_RIGHT}: FlipBookProps) {
    const [currentImage, setCurrentImage] = useState<any>(null);

    useEffect(() => {
        const firstImage = React.Children.toArray(children)[0] as any;

        if (firstImage) {
            setCurrentImage(firstImage);
        }
    }, []);

    const onClickHandler = (child: any) => () => {
        setCurrentImage(child);
    }

    return (
        <div className={cx('flipBook', nav)}>
            <div className="screen">
                {currentImage}
            </div>
            <div className="items">
                {
                    React.Children.map<ReactElement, ReactElement>(children, (child, index) => {
                        return React.cloneElement(child, {
                            key: index,
                            alt: child,
                            onClick: onClickHandler(child)
                        })
                    })
                }
            </div>
        </div>
    );
}
