import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import React, {useLayoutEffect, useState} from "react";
import SiteLoading from "./SiteLoading";
import {Helmet} from "react-helmet";

export interface DefaultLayoutProps {
    title?: string;
    children: React.ReactNode;
}

const loadingState = {
    finished: false,
    current: 0
}

export default function DefaultLayout({title, children}: DefaultLayoutProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useLayoutEffect(() => {
        const initLoading = document.getElementById('#initLoading');

        if (initLoading) initLoading.classList.add('hidden');
    }, []);

    useLayoutEffect(() => {
        if (!loadingState.finished) {
            setIsLoading(true);
        }

        loadingState.current = 0;

        const interval = setInterval(() => {
            if (loadingState.finished) return;

            const preloads = document.querySelectorAll('.preloadImage.siteInit.loading');

            if (preloads.length < 1) {
                loadingState.finished = true;

                setIsLoading(false);

                clearInterval(interval);

                return;
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="app">
            {isLoading && <SiteLoading/>}
            <Helmet titleTemplate="DeChamp.dev - %s">
                {title && <title>{title}</title>}
            </Helmet>
            <Header/>
            <main className="main">
                <div className="mainContent">
                    {children}
                </div>
            </main>
            <Footer/>
        </div>
    );
}