import React from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import PreloadImage from "../../layout/PreloadImage";
import image from '../../assets/imgs/portfolio/owl-colored.png';
import theFirst from '../../assets/imgs/portfolio/theFirst.png';
import lilWill from '../../assets/imgs/portfolio/lilWill.png';
import streetDreams from '../../assets/imgs/portfolio/StreetDreams.png';
import redWhite from '../../assets/imgs/portfolio/image30.png';
import futuristic from '../../assets/imgs/portfolio/image26.png';
import image20 from '../../assets/imgs/portfolio/image20.png';
import image21 from '../../assets/imgs/portfolio/image21.png';
import image22 from '../../assets/imgs/portfolio/image22.png';
import redrumDigitalCyanide from '../../assets/imgs/portfolio/redrumDigitalCyanide.png';
import Post from "../../layout/Post";
// @ts-ignore
import cureGrownPoc from '../../assets/videos/cureGrownPoc.mp4';
import FlipBook, {FlipBookNav} from "../../layout/FlipBook";
import ImageWithOverlayText from "../../layout/ImageWithOverlayText";

export default function Projects() {
    return (
        <DefaultLayout>
            <Post title="Swarmanize">
                <p>This one was a passion project. It was to be a community of campers, hikers and other related activities.</p>
                <FlipBook nav={FlipBookNav.BOTTOM}>
                    <ImageWithOverlayText src={image21}>
                        <p>My first attempt went live and was up for a little while.</p>
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={image20}>
                        <p>Swarmanize - v2 was to be a little more tech looking.</p>
                    </ImageWithOverlayText>
                </FlipBook>
            </Post>
            <Post title="My old projects">
                <p>
                    So I was going back in to my old stash of websites. I wish I had my originals but I have a lot
                    still.
                    It's so fun travelling back in to time to see my old styles.
                </p>
                <FlipBook>
                    <ImageWithOverlayText src={theFirst}>
                        Back then it was all about the effects and making sites look far out.
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={lilWill}>
                        Lil Will - Mad Sciontist This site was for my buddy James Williams, I really loved how it turned out when it was done.
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={streetDreams}>
                        Going with the car theme, I built this for a group.
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={redWhite}>
                        I really liked this profile site for some reason.
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={futuristic}>
                        This was my attempt to be futuristic. I feel like I got the idea across.
                    </ImageWithOverlayText>
                    <ImageWithOverlayText src={redrumDigitalCyanide}>
                        This one took after 2advanced from way back in the day.
                    </ImageWithOverlayText>
                    <img src={image22} alt="Just another site"/>
                </FlipBook>
            </Post>
            <Post title="CureGrown">
                <p>CureGrown is still a product that I might launch but it's more of a poc for right now.</p>
                <p>The goal with this site was to spread knowledge on hemp/cbd/other to help people have a safe place to
                    discuss.</p>
                <video width="100%" controls>
                    <source src={cureGrownPoc} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </Post>
            <Post title="Owl">
                <p>Owl was just something I did one day when I was board. It ended up taking me like 30 hours to
                    draw.</p>
                <PreloadImage width="740" src={image} alt="Owl-colored" imgClassName="picture"/>
            </Post>
        </DefaultLayout>
    );
}
