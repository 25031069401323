import React, {useEffect, useRef, useState} from "react";
import cx from "classnames";
import ItemLoading from "./ItemLoading";

export interface PreloadImageProps {
    src: string;
    alt: string;
    siteInit?: boolean;
    display?: string;
    [key: string]: any;
}

export default function PreloadImage({src, alt, position = 'relative', siteInit = false, className, imgClassName, ...rest}: PreloadImageProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const ref = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (!ref?.current?.complete) {
            setIsLoading(true);
        }
    }, []);

    return (
        <div className={cx(className, 'preloadImage', {'loading': isLoading}, {'siteInit': siteInit})} style={{'position': position}}>
            { isLoading && <ItemLoading/>}
            <img ref={ref} onLoad={() => {
                setIsLoading(false);
            }} src={src} alt={alt} className={imgClassName} {...rest}/>
        </div>
    );
}