import Box, {BoxColors, BoxDirection} from "./Box";
import React, {ReactNode} from "react";

export interface BlogPostProps {
    title: string;
    date?: string;
    children: ReactNode;
    onClick?: () => void;

    [key: string]: any;
}

export default function Post({title, date, children, onClick, ...rest}: BlogPostProps) {
    return (
        <div {...rest}>
            <Box style={{cursor: 'pointer'}} onClick={onClick} color={BoxColors.GREEN} direction={BoxDirection.FULL}>
                <h2 className="inline">{title}</h2>
                {
                    date && <h4 className="inline"> -{new Date(date).toLocaleDateString()}</h4>
                }
            </Box>
            <Box color={BoxColors.WHITE}>
                {children}
            </Box>
            <br/>
        </div>
    );
}